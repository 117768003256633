<template>
  <div class="view-home">
    <div class="description-block">
      <div class="container">
        <div class="description-wrapper">
          <div class="description-left">
            <div class="description-title">
              СЛУЖБА «QABILET»
            </div>
            <div class="description-subtitle">
              {{ $t('main-page.description') }}
            </div>
            <el-button round class="description-btn">Пройти тестирование</el-button>
            <div class="description-left-back">
              <el-image
                  style="width: 100%; height: 100%"
                  src="/images/desc-back-figures.png"
                  fit="contain"></el-image>
            </div>
          </div>
          <div class="description-right">
            <el-image
                style="width: 100%; height: 100%"
                src="/images/desc-back-right.png"
                fit="contain"></el-image>
          </div>
        </div>
      </div>
    </div>


    <section class="tests tests-new">
      <div class="container">
        <div class="section-title" style="margin-top: 0">
          Виды тестирования
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="title">
                ТЕСТЫ <br> ПРОФОРИЕНТАЦИИ
              </div>
              <router-link to="#" class="card-link">
                Перейти
              </router-link>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="title">
                ТЕСТЫ <br> ПРОФОРИЕНТАЦИИ
              </div>
              <router-link to="#" class="card-link">
                Перейти
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="tests reasons">
      <div class="circle-blue"></div>
      <div class="circle-navy"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <el-image
                style="width: 100%; height: 100%"
                src="/images/reasons-img.png"
                fit="contain"></el-image>
          </div>
          <div class="col-lg-5 reasons-desktop">
            <div class="title">Почему <span>Qabilet?</span></div>
            <div class="reason-item" style="margin-left: 10px">
              <div class="reason-item-left">
                <el-image
                    style="width: 50%; height: 50%"
                    src="/images/reason-img.png"
                    fit="fit"></el-image>
              </div>
              <div class="reason-item-right">
                <div class="reason-item-title">
                  «Qabilet»  ключ к раскрытию потенциала каждого ребенка, открывающий двери к будущей карьере и личностному росту
                </div>
<!--                <div class="reason-item-text">-->
<!--                  Contrary to popular belief, Lorem Ipsum is not simply random text.-->
<!--                </div>-->
              </div>
            </div>
            <div class="reason-item" style="margin-left: 20px">
              <div class="reason-item-left">
                <el-image
                    style="width: 50%; height: 50%"
                    src="/images/reason-img.png"
                    fit="fit"></el-image>
              </div>
              <div class="reason-item-right">
                <div class="reason-item-title">
                  «Qabilet» помогает найти путь к успеху, предоставляя равные возможности для развития способностей и выбора профессии
                </div>
<!--                <div class="reason-item-text">-->
<!--                  Contrary to popular belief, Lorem Ipsum is not simply random text.-->
<!--                </div>-->
              </div>
            </div>
            <div class="reason-item">
              <div class="reason-item-left">
                <el-image
                    style="width: 50%; height: 50%"
                    src="/images/reason-img.png"
                    fit="fit"></el-image>
              </div>
              <div class="reason-item-right">
                <div class="reason-item-title">
                  с «Qabilet» ваш ребенок получает точный маршрут к своим сильным сторонам и поддержку в реализации способностей
                </div>
<!--                <div class="reason-item-text">-->
<!--                  Contrary to popular belief, Lorem Ipsum is not simply random text.-->
<!--                </div>-->
              </div>
            </div>
          </div>
          <div class="reasons-mobile">
            <div class="mobile-line-top"></div>
            <div class="title">Почему <span>Qabilet</span></div>
            <div class="reason-item" style="margin-left: 10px">
              <div class="reason-item-top">
                  Инновационный подход
              </div>
              <div class="reason-item-bottom">
                Процесс профориентации более вдохновляющий и интересный для ребенка.
              </div>
            </div>
            <div class="reason-item" style="margin-left: 10px">
              <div class="reason-item-top">
                Надежность и качество
              </div>
              <div class="reason-item-bottom">
                Проверенные методики и качественные данные обеспечивают точные результаты и рекомендации для вашего ребенка.
              </div>
            </div>
            <div class="reason-item" style="margin-left: 10px">
              <div class="reason-item-top">
                Embed analytics
              </div>
              <div class="reason-item-bottom">
                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="news-main">
      <div class="container news-main-desktop">
        <div class="section-title" style="margin-top: 0">
          НОВОСТИ
        </div>
        <div class="subtitle">
          Contrary to popular belief, Lorem Ipsum is not simply random text.
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="news-left-img">
              <el-image
                  style="width: 100%; height: 100%"
                  src="/images/news-left-img.png"
                  fit="fit"></el-image>
            </div>
            <div class="heading">
              news
            </div>
            <div class="news-left-title">
              Lorem ipsum dolor sit amet
            </div>
            <div class="news-left-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
            <router-link to="#" class="news-left-link">
              Show more
            </router-link>
          </div>
          <div class="col-lg-6">
            <div class="news-right-item">
              <div class="news-item-right">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/news-item-img.png"
                    fit="fit"></el-image>
                <div class="news-item-right-label">Counseling</div>
              </div>
              <div class="news-item-left">
                <div class="news-item-left-title">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                </div>
                <div class="news-item-left-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore eiusmod tempor incididunt ut labore.
                </div>
              </div>
            </div>
            <div class="news-right-item">
              <div class="news-item-right">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/news-item-img.png"
                    fit="fit"></el-image>
                <div class="news-item-right-label">Counseling</div>
              </div>
              <div class="news-item-left">
                <div class="news-item-left-title">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                </div>
                <div class="news-item-left-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore eiusmod tempor incididunt ut labore.
                </div>
              </div>
            </div>
            <div class="news-right-item">
              <div class="news-item-right">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/news-item-img.png"
                    fit="fit"></el-image>
                <div class="news-item-right-label">Counseling</div>
              </div>
              <div class="news-item-left">
                <div class="news-item-left-title">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                </div>
                <div class="news-item-left-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore eiusmod tempor incididunt ut labore.
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="container news-main-mobile">
        <div class="mobile-line-top"></div>
        <div class="section-title">
          НОВОСТИ
        </div>
        <carousel :perPage="perPageNews" :loop="true" :autoplay="false" :autoplayTimeout="5000" :navigationEnabled="false"
                  :paginationEnabled="false" class="carousel-mobile">
          <slide v-for="slide in newsSlides" class="mobile-slide">
            <div class="carousel-mobile-item">
              <el-image
                  style="width: 100%; height: 100%"
                  src="/images/news-item-img.png"
                  fit="cover"></el-image>
              <div class="date">
                {{slide.date}}
              </div>
              <div class="title">
                {{slide.title}}
              </div>
              <a class="more-btn">
                Read more
              </a>
            </div>
          </slide>
        </carousel>
      </div>
    </section>

    <section class="useful-main">
      <div class="container">
        <div class="mobile-line-top"></div>
        <div class="section-title" style="margin-top: 0">
          Полезно педагогу
        </div>
        <div class="useful-block">
          <div class="useful-title">
            ИГРОВЫЕ ЗАДАНИЯ ПО 7 ОБЛАСТЯМ БЕЗ ЗАПИСИ ОТВЕТОВ
          </div>
          <div class="useful-text">
            Каждое задание типа "расскажи что на картинке" активирует определеные области мозга, а запись их активности делается безопасным датчиком Neurosky (США)
          </div>
        </div>

        <div class="useful-main-mobile">
          <div class="title">
            Игровые задания по 7 областям без записи ответов.
          </div>
          <div class="text">
            Каждое задание типа расскажи что на картинке активирует определенные области мозга, а запись их активности делается безопасным датчиком
          </div>
          <a class="open-btn">
            Открыть
          </a>
        </div>

      </div>
    </section>

    <section class="tests for-parents">
      <div class="container">
        <div class="mobile-line-top"></div>
        <div class="section-title" style="margin-top: 0">
          Родителям
        </div>
        <div class="cards-block">
          <router-link :to="{ name: 'parents-list', params: { id: parentsList[0].id }}" class="card-item">
            <div class="card-item-title" :style="{ 'background-image': `url(${this.parentsList[0].image_path})` }">
              {{ this.parentsList[0].title }}
            </div>
            <div class="card-item-text">
              {{ this.parentsList[0].description }}
            </div>
          </router-link>
          <router-link :to="{ name: 'parents-list', params: { id: parentsList[1].id }}" class="card-item-big">
            <div class="card-item-title" :style="{ 'background-image': `url(${this.parentsList[1].image_path})` }">
              {{ this.parentsList[1].title }}
            </div>
            <div class="card-item-text">
              {{ this.parentsList[1].description }}
            </div>
          </router-link>
          <router-link :to="{ name: 'parents-list', params: { id: parentsList[2].id }}" class="card-item">
            <div class="card-item-title" :style="{ 'background-image': `url(${this.parentsList[2].image_path})` }">
              {{ this.parentsList[2].title }}
            </div>
            <div class="card-item-text">
              {{ this.parentsList[2].description }}
            </div>
          </router-link>
        </div>

        <carousel :perPage="perPageNews" :loop="true" :autoplay="false" :autoplayTimeout="5000" :navigationEnabled="false"
                  :paginationEnabled="false" class="carousel-mobile">
          <slide class="mobile-slide parents-list-mobile">
            <router-link :to="{ name: 'parents-list', params: { id: parentsList[0].id }}" class="carousel-mobile-item">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="this.parentsList[0].image_path"
                  fit="cover"></el-image>
              <div class="title truncate-title-slide">
                {{this.parentsList[0].title}}
              </div>
              <div class="text truncate-text-slide">
                {{this.parentsList[0].description}}
              </div>
            </router-link>
          </slide>
          <slide class="mobile-slide parents-list-mobile">
            <router-link :to="{ name: 'parents-list', params: { id: parentsList[1].id }}" class="carousel-mobile-item">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="this.parentsList[1].image_path"
                  fit="cover"></el-image>
              <div class="title truncate-title-slide">
                {{this.parentsList[1].title}}
              </div>
              <div class="text truncate-text-slide">
                {{this.parentsList[1].description}}
              </div>
            </router-link>
          </slide>
          <slide class="mobile-slide parents-list-mobile">
            <router-link :to="{ name: 'parents-list', params: { id: parentsList[2].id }}" class="carousel-mobile-item">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="this.parentsList[2].image_path"
                  fit="cover"></el-image>
              <div class="title truncate-title-slide">
                {{this.parentsList[2].title}}
              </div>
              <div class="text truncate-text-slide">
                {{this.parentsList[2].description}}
              </div>
            </router-link>
          </slide>
        </carousel>

        <router-link :to="{ name: 'parents-new-block', hash: '#parentsList' }" class="events-link">
          Все мероприятия
        </router-link>
      </div>
    </section>

    <section class="tests forums">
      <div class="container">
        <div class="mobile-line-top"></div>
        <div class="section-title" style="margin-top: 0">
          МЕРОПРИЯТИЯ
        </div>
        <div class="forums-cards">
          <div class="col-md-6">
            <div class="news-left-img">
              <el-image
                  style="width: 100%; height: 100%"
                  src="/images/forums-back.png"
                  fit="fit"></el-image>
            </div>
            <div class="heading">
              news
            </div>
            <div class="news-left-title">
              Lorem ipsum dolor sit amet
            </div>
            <div class="news-left-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
          <div class="col-md-6">
            <div class="news-left-img">
              <el-image
                  style="width: 100%; height: 100%"
                  src="/images/forums-back.png"
                  fit="fit"></el-image>
            </div>
            <div class="heading">
              news
            </div>
            <div class="news-left-title">
              Lorem ipsum dolor sit amet
            </div>
            <div class="news-left-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
          </div>
        </div>
        <router-link to="#" class="events-link">
          Все мероприятия
        </router-link>
      </div>
    </section>

    <section class="tests reviews-main">
      <div class="current-review">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="reviews-title">
                Отзывы Qabilet
              </div>
              <div class="reviews-text">
                Здесь представлены отзывы отзывы пользователей проекта: участников тестирования и их родителей, а также
                менеджеров центров, психологов центров, психологов школ.
              </div>
            </div>
            <div class="col-lg-6">
              <div class="current-review-card">
                <div class="card-img">
                  <el-image
                      style="width: 100%; height: 100%"
                      src="/images/review-img.png"
                      fit="fit"></el-image>
                </div>
                <div class="card-text">
                  <div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </div>
                  <div class="card-info">
                    <span>{{currentReview.name}}</span>
                    <span>Участник тестирования</span>
                    <span>Восточно-Казахстанская область</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <carousel :perPage="1" :loop="true" :autoplay="false" :autoplayTimeout="5000" :navigationEnabled="true"
                    :paginationEnabled="false" class="carousel-main-mobile">
            <slide v-for="slide in slides" class="current-review-card-mobile">
              <div style="padding: 10px">
                <div class="card-top">
                  <div class="card-img">
                    <el-image
                        style="width: 100%; height: 100%"
                        src="/images/review-img.png"
                        fit="fit"></el-image>
                  </div>
                  <div class="card-info">
                    <p>
                      {{slide.name}}
                    </p>
                    <p>
                      {{slide.info}}
                    </p>
                    <p>
                      {{slide.region}}
                    </p>
                  </div>
                </div>
                <div class="card-bottom">
                  {{slide.text}}
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
      <div class="container" style="position: relative">
<!--        <div class="reviews-list" ref="content">-->
<!--          <div class="current-review-card" v-for="item in reviews" @click="currentReview = item">-->
<!--            <div class="card-img">-->
<!--              <el-image-->
<!--                  style="width: 100%; height: 100%"-->
<!--                  src="/images/review-forums-img-back2.png"-->
<!--                  fit="fit"></el-image>-->
<!--            </div>-->
<!--            <div class="card-text">-->
<!--              <div>-->
<!--                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.-->
<!--              </div>-->
<!--              <div class="card-info">-->
<!--                <span>Ахметова Айжан, 9 лет</span>-->
<!--                <span>Участник тестирования</span>-->
<!--                <span>Восточно-Казахстанская область</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <carousel :perPage="3" :loop="true" :autoplay="true" :autoplayTimeout="5000" :navigationEnabled="true"
                  :paginationEnabled="false" class="carousel-main">
          <slide v-for="slide in slides" class="current-review-card">
            <div style="padding: 10px" @click="currentReview = slide">
              <div class="card-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/review-img.png"
                    fit="fit"></el-image>
              </div>
              <div class="card-text">
                <div>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat.
                </div>
                <div class="card-info">
                  <span>Ахметова Айжан, 9 лет</span>
                  <span>Участник тестирования</span>
                  <span>Восточно-Казахстанская область</span>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </section>

  </div>
</template>
<script>
import {Carousel, Slide} from 'vue-carousel';
// import VueCarousel from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide
  },
  methods: {
    scrollRight() {
      this.$refs.content.scrollLeft += 100;
    },
    scrollLeft() {
      this.$refs.content.scrollLeft -= 100;
    },
    pageChange(number) {
      if (window.innerWidth < 992) {
        this.tab = 'comment' + (number + 1);
      } else {
        this.tab = 'comment' + (number + 3);
      }
    },
    paginationClick() {

    },
    slidePre() {
      // console.log(this.$refs.slider);
      this.$refs.slider.$emit('slidePre');
      // this.$emit('slidePre');
    },
    slideNext() {
      this.$refs.slider.$emit('slideNext');
      // this.$emit('slideNext');
    },
    getMapData(id) {
      if (id == 0) {
        this.currentName = this.$t('main-page.qabilet-in-numbers')
        this.usersCount = this.allUsersCount;
        this.regionalCenters = this.allRegionalCenters;
        this.diagnostickTests = this.allDiagnostickTests;
        this.profOrientationTests = this.allProfOrientationTests;
        this.consultationsCount = this.allConsultationsCount;
        this.psychCount = this.allPsychCount;
      } else {
        for (var i = 0; i < this.regions.length; i++) {
          if (this.regions[i].id == id) {
            this.currentName = this.regions[i].name
          }
        }
        this.usersCount = this.map[id].users
        this.regionalCenters = this.map[id].centers;
        this.diagnostickTests = this.map[id].diagnostic_quizzes;
        this.profOrientationTests = this.map[id].prof_quizzes;
        this.consultationsCount = this.map[id].psychologist_requests;
        this.psychCount = this.map[id].service_psychologists
      }
    }

  },

  mounted() {

    this.$http.get(window.API_NEWS + '/api/news-list/15?project_id=2')
        .then(res => {
          console.log(res.body)
          this.news = res.body
        })

    this.$http.get(window.API_ROOT + '/api/parents/list')
        .then(res => {
          this.parentsList = res.body.data
          this.parentsList.sort( (a, b) => {
            // сортирую, чтобы вначале шли свежие новости
            let dateA = new Date(a.created_at);
            let dateB = new Date(b.created_at);
            return dateB - dateA;
          })
        })

    this.logged = !(localStorage.getItem('token') === null || localStorage.getItem('token') === '');
    this.$http.get(window.API_ROOT + '/api/references/regions')
        .then((res) => {
          this.regions = res.body.data;
          this.$http.get(window.API_ROOT + '/api/get-json/map')
              .then((res) => {
                this.map = res.body
                for (var i = 1; i <= this.regions.length; i++) {
                  this.allUsersCount = this.allUsersCount + this.map[i].users;
                  this.allRegionalCenters = this.allRegionalCenters + this.map[i].centers;
                  this.allDiagnostickTests = this.allDiagnostickTests + this.map[i].diagnostic_quizzes;
                  this.allProfOrientationTests = this.allProfOrientationTests + this.map[i].prof_quizzes;
                  this.allConsultationsCount = this.allConsultationsCount + this.map[i].psychologist_requests;
                  this.allPsychCount = this.allPsychCount + this.map[i].service_psychologists;
                  this.usersCount = this.allUsersCount;
                  this.regionalCenters = this.allRegionalCenters;
                  this.diagnostickTests = this.allDiagnostickTests;
                  this.profOrientationTests = this.allProfOrientationTests;
                  this.consultationsCount = this.allConsultationsCount;
                  this.psychCount = this.allPsychCount;
                }
              })
        })


    if (window.innerWidth < 992) {
      this.tab = 'comment1';
      this.perPageNews = 2
    }

    if (window.innerWidth < 500) {
      this.perPageNews = 1
    }
  },
  filters: {
    date:function (value){
      return  new Date(value).toLocaleDateString();
    },
    Numerize: function (value) {
      return value.replace(/<\/?[^>]+>/g,'').replace(/&nbsp;/g,' ');
    }
  },
  data() {
    return {
      // reviews: [
      //   {photo: "", name: "Ахметова Айжан, 10 лет", region: "Восточно-Казахстанская область", info: "Участник тестирования", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",},
      //   {photo: "", name: "Ахметова Айжан, 11 лет", region: "Восточно-Казахстанская область", info: "Участник тестирования", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",},
      //   {photo: "", name: "Ахметова Айжан, 12 лет", region: "Восточно-Казахстанская область", info: "Участник тестирования", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",},
      //   {photo: "", name: "Ахметова Айжан, 13 лет", region: "Восточно-Казахстанская область", info: "Участник тестирования", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",},
      //   {photo: "", name: "Ахметова Айжан, 14 лет", region: "Восточно-Казахстанская область", info: "Участник тестирования", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",},
      // ],
      slides: [
        {photo: "", name: "Ахметова Айжан, 10 лет", region: "Восточно-Казахстанская область", info: "Участник тестирования", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",},
        {photo: "", name: "Ахметова Айжан, 11 лет", region: "Восточно-Казахстанская область", info: "Участник тестирования", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",},
        {photo: "", name: "Ахметова Айжан, 12 лет", region: "Восточно-Казахстанская область", info: "Участник тестирования", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",},
        {photo: "", name: "Ахметова Айжан, 13 лет", region: "Восточно-Казахстанская область", info: "Участник тестирования", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",},
        {photo: "", name: "Ахметова Айжан, 14 лет", region: "Восточно-Казахстанская область", info: "Участник тестирования", text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",},
      ],
      newsSlides: [
        {img: "", date: "22 June 2022", title: "Staffing software: key capabilities and top products", },
        {img: "", date: "22 June 2022", title: "Staffing software: key capabilities and top products", },
        {img: "", date: "22 June 2022", title: "Staffing software: key capabilities and top products", },
      ],
      parentsList: [],
      perPageNews: 1,
      currentReview: "",
      currentName: this.$t('main-page.qabilet-in-numbers'),
      tab: 'comment3',
      map: {},
      regions: [],
      usersCount: 0,
      diagnostickTests: 0,
      profOrientationTests: 0,
      consultationsCount: 0,
      regionalCenters: 0,
      psychCount: 0,
      allUsersCount: 0,
      allDiagnostickTests: 0,
      allProfOrientationTests: 0,
      allConsultationsCount: 0,
      allRegionalCenters: 0,
      allPsychCount: 0,
      logged: false,
      news: {
        news:{
          data:[]
        }
      },
      backUrlImg: window.API_NEWS + '/uploads/',
      backUrlNews: window.API_NEWS + '/one-news/',
    }
  }
}
</script>
<style>

.news-main-mobile,
.reasons-mobile,
.mobile-line-top,
.useful-main-mobile,
.carousel-main-mobile,
.carousel-mobile

{
  display: none;
}

@media screen and (max-width: 1200px) {
  .tests-new .card {
    height: 260px;
  }

  .news-item-right {
    max-height: 110px;
  }

  .news-item-left-title {
    font-size: 14px;
  }

  .news-item-left-text {
    font-size: 14px;
  }

  .news-item-right-label {
    font-size: 10px;
    padding: 5px 10px;
  }

  .for-parents .cards-block {
    gap: 50px;
  }

  .for-parents .card-item .card-item-title {
    font-size: 16px;
  }

  .for-parents .card-item .card-item-text {
    font-size: 14px;
  }

  .for-parents .card-item-big .card-item-text {
    font-size: 14px;
  }

  .for-parents .card-item-big .card-item-title {
    font-size: 16px;
  }

  .current-review-card .card-img {
    height: 400px;
  }

  .carousel-main .VueCarousel-navigation-prev {
    display: none;
  }

  .carousel-main .VueCarousel-navigation-next {
    display: none;
  }
}


@media screen and (max-width: 992px) {

  .carousel-main-mobile {
    display: block;
    margin-top: 70px;
  }

  .carousel-mobile {
    display: block;
  }

  .mobile-line-top {
    display: block;
  }

  .description-right {
    display: none;
  }

  .description-left-back {
    display: none;
  }

  .description-left {
    width: 100%;
  }

  .description-title {
    font-size: 30px;
    width: 80%;
  }

  .description-subtitle {
    font-size: 16px;
    width: 80%;
  }

  .description-btn {
    width: 100%;
    margin-top: 40px;
    font-size: 19px;
  }

  .description-block {
    height: 430px;
    border-radius: 0;
  }

  .description-block::before {
    width: 100%;
    height: 81px;
    content: "";
    position: absolute;
    top: 440px;
    left: 0;
    border-radius: 50%;
    background: linear-gradient(to right, #32436D, #1A84B2, #02C7FA);

    display: none;
  }

  .tests-new .section-title {
    display: none;
  }

  .tests-new {
    background-image: none;
    margin-top: 20px;
  }

  .tests-new .card {
    height: 190px;
    padding: 60px 0 0 0;
  }

  .tests-new .title {
    font-size: 20px;
  }

  .tests-new .card-link {
    margin-top: auto;
    width: 100%;
    border-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
  }

  .circle-blue, .circle-navy {
    display: none;
  }

  .reasons-desktop {
    display: none;
  }

  .reasons-mobile {
    padding: 0 15px;
    display: block;
  }

  .reasons-mobile .reason-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .reasons-mobile .title {
    text-align: left;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 30px;
  }

  .reason-item-top {
    font-size: 20px;
    font-weight: 700;
    color: #32436D;
    position: relative;
    padding-left: 50px;
  }

  .reason-item-top::before {
    width: 32px;
    height: 32px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../../public/images/reasons-item-back.svg");
    background-size: 50% 50%;
    background-position: center ;
    background-repeat: no-repeat;
    box-shadow: 0px 8.35px 24.5px 0px rgba(13, 15, 28, 0.08);
    border-radius: 50%;
  }

  .reason-item-bottom {
    font-size: 16px;
    font-weight: 400;
    color: #9497A1;
    line-height: 28px;
  }

  .news-main-desktop {
    display: none;
  }

  .news-main-mobile {
    display: block;
  }

  .news-main-mobile .section-title {
    font-size: 30px;
    font-weight: 800;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .parents-list-mobile .carousel-mobile-item {
    display: inline-block;
    text-decoration: none;
    padding-right: 20px;
  }

  .parents-list-mobile .carousel-mobile-item .el-image {
    border-radius: 10px;
  }

  .carousel-mobile-item {
    padding-right: 20px;
  }

  .carousel-mobile-item .date {
    font-weight: 400;
    font-size: 14px;
    color: #9497A1;
    margin: 20px 0 12px 0;
  }

  .carousel-mobile-item .title {
    font-weight: 700;
    font-size: 20px;
    color: #32436D;
  }

  .carousel-mobile-item .more-btn {
    font-weight: 600;
    font-size: 16px;
    color: #185CFF;
    margin: 12px 0;
    position: relative;
    display: inline-block;
    padding-right: 30px;
  }

  .carousel-mobile-item .more-btn::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -6px;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../public/images/icon_arrow_right.svg");
  }

  .tests-new {
    padding-bottom: 0;
  }

  .useful-main {
    background-image: none;
  }

  .useful-main .mobile-line-top {
    margin-bottom: 30px;
  }

  .useful-main .section-title {
    text-align: left;
    margin-bottom: 30px;
  }

  .useful-main .useful-title {
    text-align: left;
    font-size: 26px;
    width: 80%;
  }

  .useful-main .useful-text {
    text-align: left;
    width: 80%;
  }

  .useful-main-mobile {
    display: block;
  }

  .useful-main-mobile .title {
    font-weight: 700;
    font-size: 20px;
    color: #9497A1;
    margin: 30px 0;
  }

  .useful-main-mobile .text {
    font-weight: 400;
    font-size: 20px;
    color: #9497A1;
  }

  .useful-main-mobile .open-btn {
    display: inline-block;
    width: 100%;
    background-color: #02C7FA;
    color: #FFF;
    text-align: center;
    padding: 20px;
    border-radius: 6px;
    margin-top: 30px;
  }

  .for-parents .section-title {
    text-align: left;
  }

  .for-parents .mobile-line-top {
    margin-bottom: 30px;
  }

  .for-parents .cards-block {
    display: none;
  }

  .for-parents .events-link {
    display: none;
  }

  .for-parents .carousel-mobile-item .title {
    font-weight: 700;
    font-size: 20px;
    color: #32436D;
    margin-bottom: 16px;
  }

  .for-parents .carousel-mobile-item .text {
    font-weight: 400;
    font-size: 16px;
    color: #9497A1;
  }

  .forums .mobile-line-top {
    margin-bottom: 30px;
  }

  .forums .section-title {
    text-align: left;
    margin-bottom: 30px;
  }

  .forums-cards {
    margin-top: 0;
    flex-direction: row;
  }

  .forums .col-lg-6 {
    padding: 0;
  }

  .reviews-main .reviews-title {
    text-align: left;
    margin-top: 0;
  }

  .reviews-main .reviews-text {
    text-align: left;
  }

  .carousel-main {
    display: none;
  }

  .current-review-card {
    display: none;
  }

  .reviews-main .current-review {
    background-image: none;
  }

  .current-review-card-mobile {
    padding: 35px;
    background: #FFF;
    border-radius: 6px;
    box-shadow: 0px 4px 12px 0px rgba(12, 68, 204, 0.1);
  }

  .current-review-card-mobile .card-img {
    width: 50px;
    height: 50px;
  }

  .current-review-card-mobile .card-img .el-image {
    border-radius: 50%;
  }

  .truncate-title-slide {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .truncate-text-slide {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .current-review-card-mobile .card-top {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
  }

  .card-top .card-info p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #9497A1;
  }

  .card-bottom {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #9497A1;
  }

  .carousel-main-mobile .VueCarousel-navigation-prev {
    color: #32436D !important;
    background: #C9DCEC;
    width: 48px;
    height: 40px;
    background-image: none;
    position: absolute;
    left: 50px !important;
    top: -33px;
    cursor: pointer;
    border-radius: 14px;
  }

  .carousel-main-mobile .VueCarousel-navigation-next {
    color: #32436D !important;
    background: #C9DCEC;
    width: 48px;
    height: 40px;
    background-image: none;
    position: absolute;
    left: 20px !important;
    top: -33px;
    cursor: pointer;
    border-radius: 14px;
  }

}

@media screen and (max-width: 500px) {
  .forums-cards {
    flex-direction: column;
  }
}

#paper path:hover {
  fill: #1c5fc2;
  cursor: pointer;
}

#paper path {
  stroke: #fff;
  stroke-width: 1px;
  transition: all .3s linear;
}

.text-transition {
  transition: all .3s linear;
}

.slide-pre,
.slide-next {
  position: absolute;
  top: calc(50% - 25px);
  border: none;
  background-color: transparent;
}

.slide-pre:active,
.slide-pre:hover,
.slide-next:active,
.slide-next:hover {
  outline: none;
  cursor: pointer;
}

.slide-next {
  right: -50px;
}

.slide-pre {
  left: -50px;
}

.breadcrumb--main-page {
  display: none !important;
}

@media (max-width: 991px) {
  .breadcrumb--main-page {
    display: block !important;
  }

  .nur-sultan {
    left: 60%;
    top: 28%;
  }

  .almaty {
    right: 26%;
    top: 78%;
  }

  .shymkent {
    left: 54%;
    bottom: 31px;
  }
}

@media (max-width: 767px) {
  .nur-sultan {
    left: 65%;
    top: 27%;
  }


  .almaty {
    right: 15%;
    top: 76%;
  }

  .shymkent {
    left: 57%;
    bottom: 46px;
  }
}

@media (max-width: 575px) {
  .nur-sultan {
    left: 56%;
    top: 24%;
  }


  .almaty {
    right: 31%;
    top: 43.7%;

  }

  .shymkent {
    left: 52%;
    bottom: 253px;
  }
}

@media (max-width: 520px) {
  .nur-sultan {
    left: 57%;
    top: 24%;
  }


  .almaty {
    right: 29%;
    top: 43.7%;

  }

  .shymkent {
    left: 53%;
    bottom: 253px;
  }
}

@media (max-width: 480px) {
  .nur-sultan {
    left: 57%;
    top: 21%;
  }


  .almaty {
    right: 27%;
    top: 42.7%;

  }

  .shymkent {
    left: 52%;
    bottom: 226px;
  }
}

@media (max-width: 449px) {
  .nur-sultan {
    left: 59%;
    top: 21%;
  }


  .almaty {
    right: 25%;
    top: 42.7%;

  }

  .shymkent {
    left: 54%;
    bottom: 226px;
  }
}

@media (max-width: 410px) {
  .nur-sultan {
    left: 59%;
    top: 21%;
  }


  .almaty {
    right: 24%;
    top: 42.7%;

  }

  .shymkent {
    left: 54%;
    bottom: 226px;
  }
}

@media (max-width: 375px) {
  .nur-sultan {
    left: 62%;
    top: 21%;
  }


  .almaty {
    right: 21%;
    top: 42.7%;

  }

  .shymkent {
    left: 56%;
    bottom: 226px;
  }
}
</style>
